import React from 'react';

const style = require('./Label.module.scss');

type LabelProps = {
  children: React.ReactNode;
  [otherProps: string]: any;
};

const Label = (props: LabelProps) => {
  const { children } = props;

  return (
    <label {...props} className={style.label}>
      {children}
    </label>
  );
};

export default Label;
