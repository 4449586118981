// extracted by mini-css-extract-plugin
export var stepperComponent = "Stepper-module--stepperComponent--RdZXU";
export var stepsContainer = "Stepper-module--stepsContainer--1XAKi";
export var step = "Stepper-module--step--2dAqG";
export var stepComplete = "Stepper-module--stepComplete--ttK8b";
export var stepPending = "Stepper-module--stepPending--QYHzm";
export var stepNumber = "Stepper-module--stepNumber--2wfp_";
export var stepCircle = "Stepper-module--stepCircle--2gMuv";
export var stepText = "Stepper-module--stepText--1NeZ8";
export var progressBar = "Stepper-module--progressBar--ImfbQ";
export var progress = "Stepper-module--progress--3tgB6";
export var progressCompleted = "Stepper-module--progressCompleted--1e678";
export var progressPending = "Stepper-module--progressPending--1ipOk";