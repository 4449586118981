import React from 'react';

type InputSelectOptionProps = {
  children: React.ReactNode;
  value: string;
  className?: string;
  [otherProps: string]: any;
};

const InputSelectOption = (props: InputSelectOptionProps) => {
  const { children, className, value, ...rest } = props;

  return (
    <div {...rest} className={`${className}`} data-value={value}>
      {children}
    </div>
  );
};

InputSelectOption.defaultProps = {
  className: '',
};

export default InputSelectOption;
