import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { CREATE_MEMBER } from '../../../apollo/queries';
import {
  CreateMember,
  CreateMemberVariables,
} from '../../../apollo/__generated__/CreateMember';
import Stepper from '../../ui/Stepper';
import ButtonGroup from '../../ui/ButtonGroup';
import Button from '../../ui/Button';
import {
  getHasSaveLeast,
  getHasFullName,
  getHasEmail,
  setHasSaveLeast,
  setHasFullName,
  setHasEmail,
  getLocalityLS,
  getSelectedDistributorLS,
  getHouseholdSize,
  getBillPresenceType,
  getHasSolarSystem,
  getCurrentRetailer,
  getCurrentPlanLS,
  getHasLifeSupportEquipmant,
  yesNoToBool,
  getResidenceId,
  getBusinessId,
  getHasMemberId,
  setHasMemberId,
  setResultsToken,
} from '../../../utils/localStorage/localStorageFunctions';
import InputGroup from '../../ui/InputGroup';
import Label from '../../ui/Label';
import InputSelect from '../../ui/InputSelect';
import InputSelectOption from '../../ui/InputSelectOption';
import Input from '../../ui/Input';
import CheckBoxGroup from '../../ui/CheckBoxGroup';
import CheckBox from '../../ui/CheckBox';
// @ts-ignore
import { ReactComponent as LogoWTagline } from '../../../assets/vectors/LogoWTagline.svg';
import { FiltersType } from "../../../utils/sessionStorage/sessionStorageTypes";
import {
  getSelectedPlanTypeFiltersByKeys,
} from '../../../utils/calculationUtils';

const style = require('./EmailPopup.module.scss');

type EmailPopupProps = {
  togglePopup: (value: boolean) => void;
  activeFilters: FiltersType | undefined;
};

const ContinueSavingSchema = Yup.object().shape({
  fullName: Yup.string().nullable().required('Please enter your name'),
  email: Yup.string().email('Please enter your email').nullable(),
});

export const EmailPopup = ({
  togglePopup,
  activeFilters,
  ...restProps
}: EmailPopupProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createUser, { data: createUserData, loading: createUserLoading }] =
    useMutation<CreateMember, CreateMemberVariables>(CREATE_MEMBER);

  const planTypeFilters = getSelectedPlanTypeFiltersByKeys(
    activeFilters?.planType || [],
    false
  );

  const [initialeFormValues] = useState(() => {
    const saveLeastLS = getHasSaveLeast();
    const fullNameLS = getHasFullName();
    const emailLS = getHasEmail();
    const memberId = getHasMemberId();

    return {
      saveLeast: saveLeastLS?.toString() || '',
      fullName: fullNameLS || '',
      email: emailLS || '',
      memberId,
      accept: [],
    };
  });

  const handleCreateUser = (values: typeof initialeFormValues) => {
    const saveLeastInt = parseInt(values.saveLeast, 10);
    setHasSaveLeast(saveLeastInt);
    setHasFullName(values.fullName);
    setHasEmail(values.email);

    const locality = getLocalityLS();
    const distributor = getSelectedDistributorLS();
    const householdSize = getHouseholdSize();
    const billPresenceType = getBillPresenceType();
    const hasSolarSystem = getHasSolarSystem();
    const retailer = getCurrentRetailer();
    const currentPlan = getCurrentPlanLS();
    const lifeSupport = getHasLifeSupportEquipmant();

    const additionals = {
      postcode: locality?.postcode?.postcode,
      locality: locality?.name,
      locality_id: locality?.id,
      distributor: distributor?.name,
      household: householdSize,
      bill_info:
        billPresenceType === 'pdfBill' || billPresenceType === 'paperBill',
      solar: yesNoToBool(hasSolarSystem),
      current_retailer_name: retailer?.orgName,
      current_plan_name: currentPlan?.name,
      current_plan_ID: currentPlan?.id,
      life_support: lifeSupport,
      results_url: '',
      bill_type: billPresenceType
    };

    const propertyId = getResidenceId();
    const businessId = getBusinessId();
    if (!(propertyId || businessId)) return;

    const emailPopupStatus = localStorage.getItem('popup_status');
    createUser({
      variables: {
        fname: values.fullName,
        lname: '',
        email: values.email,
        // @ts-ignore
        propertyId: businessId ?? propertyId,
        additionalInfo: JSON.stringify(additionals),
        selectedPlanId: currentPlan?.id || '',
        selectedRetailerId: retailer?.id || '',
        planTypeFilters,
      },
    })
      .then((value) => {
        const memberId = value.data?.createMember?.member?.id;
        if (!memberId) return;
        setHasMemberId(memberId);

        const resultsToken = value.data?.createMember?.member?.resultsToken;
        if (!resultsToken) return;
        setResultsToken(resultsToken);
        if (!emailPopupStatus) {
          localStorage.setItem('popup_status', '1');
          togglePopup(false);
        }
      })
      .catch(() => {
        localStorage.setItem('popup_status', '1');
        togglePopup(false);
      });
  };

  return (
    <div className={style.emailPopupWrapper}>
      <div className={style.emailPopup}>
        <header {...restProps} className={style.header}>
          <LogoWTagline width={250} height={41} />
        </header>
        <Stepper
          className="my-3"
          steps={['About you', 'About your bill', 'Your Details']}
          activeStage={3}
          progress={100}
        />
        <Formik
          initialValues={initialeFormValues}
          onSubmit={handleCreateUser}
          validationSchema={ContinueSavingSchema}
        >
          {(formikProps) => {
            return (
              <Form>
                <h2 className="my-2">Subscribe and Switch with Huglo</h2>
                <p className="sub-heading">
                  Switch plans with Huglo and we will notify you when you can
                  save more with a better value plan.
                </p>
                <InputGroup className="my-3">
                  <Label htmlFor="saveLeast">
                    Notify me when I save at least
                  </Label>
                  <InputSelect
                    id="saveLeast"
                    name="saveLeast"
                    placeholder="Notify Amount"
                  >
                    <InputSelectOption value="100">$100+</InputSelectOption>
                    <InputSelectOption value="200">$200+</InputSelectOption>
                    <InputSelectOption value="300">$300+</InputSelectOption>
                  </InputSelect>
                  <div className={style.personalData}>
                    <div className={style.name}>
                      <Label htmlFor="fullName">Full Name</Label>
                      <Input type="text" name="fullName" id="fullName" />
                    </div>
                    <div className={style.email}>
                      <Label htmlFor="email">Email</Label>
                      <Input type="text" name="email" id="email" />
                    </div>
                  </div>
                </InputGroup>
                <CheckBoxGroup
                  className={`${style.checkBoxTermsAndConditions} my-4`}
                >
                  <CheckBox value="accept" name="accept" id="chkAccept">
                    I understand that Huglo Pty Ltd (ABN 20 633 472 090) (Huglo)
                    compares plans from all electricity retailers in the market
                    strictly on price. When switching with Huglo it may earn
                    referral fees with selected retailers, but Huglo does not
                    mark-up plan cost with any additional fees or charges when
                    you switch with Huglo. I have read and accept the{' '}
                    <a
                      href="https://www.huglo.com.au/terms-conditions"
                      target="_blank"
                      rel="noreferrer nofollower"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{ fontSize: '16px' }}
                    >
                      Terms and Conditions
                    </a>
                    ,{' '}
                    <a
                      href="https://www.huglo.com.au/privacy-collection-statement"
                      target="_blank"
                      rel="noreferrer nofollower"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{ fontSize: '16px' }}
                    >
                      Privacy Collection Notice
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://www.huglo.com.au/privacy-policy"
                      target="_blank"
                      rel="noreferrer nofollower"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{ fontSize: '16px' }}
                    >
                      Privacy Policy.
                    </a>
                  </CheckBox>
                </CheckBoxGroup>
                <ButtonGroup className="my-5">
                  <Button
                    className="subscribe-btn"
                    type="submit"
                    disabled={
                      !(
                        formikProps.values.saveLeast &&
                        formikProps.values.fullName &&
                        formikProps.values.email &&
                        formikProps.values.accept.length
                      )
                    }
                  >
                    Continue
                  </Button>
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => togglePopup(false)}
                  >
                    Skip
                  </Button>
                </ButtonGroup>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
