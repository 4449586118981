import React from 'react';
import { useField } from 'formik';
// @ts-ignore
import { ReactComponent as PlusIcon } from '../../../assets/vectors/PlusIcon.svg';
// @ts-ignore
import { ReactComponent as MinusIcon } from '../../../assets/vectors/MinusIcon.svg';

const classNames = require('classnames');
const style = require('./Input.module.scss');

type InputProps = {
  name: string;
  id: string;
  type: 'text' | 'number' | 'date' | 'location';
  unit?: string;
  // eslint-disable-next-line react/boolean-prop-naming
  controls?: boolean;
  className?: string;
  [otherProps: string]: any;
};

const Input = (props: InputProps) => {
  const {
    type,
    id,
    unit,
    controls,
    className,
    min,
    max,
    onChange: customOnChange,
    ...rest
  } = props;

  const actualType = type === 'location' ? 'text' : type;

  const [field, meta, helpers] = useField({ ...props, type: actualType });

  const { name, onBlur, onChange, value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  const floatValue = +(Math.floor(value * 100) / 100).toFixed(1);

  const incrementValue = () => {
    if (floatValue + 1 > max) return;
    setValue(floatValue + 1);
  };

  const decrementValue = () => {
    if (floatValue - 1 < min) return;
    setValue(floatValue - 1);
  };

  const getExtraContent = () => {
    if (!['text', 'number'].includes(type)) return;
    if (unit && controls) {
      return (
        <div className={style.extraContent}>
          <PlusIcon
            width={20}
            height={20}
            className={style.control}
            onClick={() => {
              incrementValue();
            }}
          />
          <MinusIcon
            width={20}
            height={20}
            className={style.control}
            style={{ marginLeft: '15px' }}
            onClick={() => {
              decrementValue();
            }}
          />
          <div
            className={style.unit}
            style={{
              marginLeft: '15px',
              minWidth: '50px',
            }}
            tabIndex={-1}
          >
            {unit}
          </div>
        </div>
      );
    }
    if (unit) {
      return (
        <div className={style.extraContent}>
          <div className={style.unit}>{unit}</div>
        </div>
      );
    }
    if (controls) {
      return (
        <div className={`${style.extraContent}`}>
          <PlusIcon
            width={20}
            height={20}
            className={style.control}
            onClick={() => {
              incrementValue();
            }}
          />
          <MinusIcon
            width={20}
            height={20}
            className={style.control}
            style={{ marginLeft: '10px' }}
            onClick={() => {
              decrementValue();
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className={`${style.inputComponent} ${className}`}>
      <div className={style.inputWrapper}>
        <input
          {...rest}
          type={actualType}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={floatValue > 0 ? floatValue : value}
          min={min}
          max={max}
          className={classNames(style.input, {
            [style.inputInvalid]: touched && error,
            [style.extraPadding]: unit || controls || type === 'location',
            [style.extraPaddingWide]: unit && controls,
            [style.removeArrows]: controls,
            [style.inputDate]: type === 'date',
            [style.bgLocationIcon]: type === 'location',
          })}
        />
        {getExtraContent()}
      </div>
      {touched && error && <div className={style.errorText}>{error}</div>}
    </div>
  );
};

Input.defaultProps = {
  className: '',
  controls: false,
  unit: '',
};

export default Input;
