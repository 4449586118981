import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import useOnClickOutsideExceptClasses from '../../../hooks/useOnClickOutsideExceptClasses';

const style = require('./InputSelect.module.scss');

type InputSelectProps = {
  children: React.ReactNode;
  className?: string;
  name: string;
  id: string;
  placeholder?: string;
  initialValueText?: string;
  [otherProps: string]: any;
};

const InputSelect = (props: InputSelectProps) => {
  const { children, className, placeholder, initialValueText, id, ...rest } =
    props;

  const [field, meta, helpers] = useField(props);

  const { value } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [activeValue, setActiveValue] = useState(value);
  const [activeText, setActiveText] = useState(() => {
    if (initialValueText) return initialValueText;
    if (!value) return placeholder;

    // Search for a child element which has a matching value to extract its innerText
    const childElementWithValue = React.Children.toArray(children).find(
      (child) => {
        if (React.isValidElement(child)) {
          return child?.props?.value === value;
        }
        return false;
      }
    );

    if (!React.isValidElement(childElementWithValue)) return placeholder;

    return childElementWithValue?.props?.children?.toString();
  });

  useEffect(() => {
    if (!value) {
      setActiveText(placeholder);
      setTouched(false);
    }
  }, [value, placeholder]);

  const toggleDropDown = () => {
    if (isDropDownOpen) setTouched(true, false);
    setIsDropDownOpen((prevState) => !prevState);
  };

  useOnClickOutsideExceptClasses(`${id}-click-outside`, () => {
    if (isDropDownOpen) {
      setIsDropDownOpen(false);
      setTouched(true);
    }
  });

  const setOptionAriaSelected = (e: React.FocusEvent) => {
    const options = document.getElementsByClassName(style.option);
    for (let i = 0; i < options.length; i += 1) {
      options[i].setAttribute('aria-selected', 'false');
    }
    e.currentTarget.setAttribute('aria-selected', 'true');
  };

  const setActiveOption = (
    e: React.MouseEvent<any> | React.KeyboardEvent<any>
  ) => {
    setActiveValue(e.currentTarget.dataset?.value);
    setValue(e.currentTarget.dataset?.value);
    setActiveText(e.currentTarget.innerText);
    toggleDropDown();
    document.getElementById(id)?.focus();
  };

  const formattedChildren = React.Children.map(children, (option, index) => {
    if (React.isValidElement(option)) {
      return React.cloneElement(option, {
        key: index,
        className: style.option,
        onKeyPress: (e: React.KeyboardEvent<any>) => {
          if (e.key === ' ' || e.key === 'Enter') {
            setActiveOption(e);
          }
        },
        onClick: (e: React.MouseEvent<any>) => {
          setActiveOption(e);
        },
        onFocus: (e: React.FocusEvent) => {
          setOptionAriaSelected(e);
        },
        role: 'option',
        'aria-selected': false,
        tabIndex: 0,
        id: `${id}-${option?.props?.value}`,
      });
    }
  });

  return (
    <div className={`${style.inputSelectComponent} ${className}`}>
      <div className={`${style.inputSelectWrapper} ${id}-click-outside`}>
        <div
          {...rest}
          role="button"
          className={`${style.inputSelect} ${
            touched && error ? style.inputInvalid : ''
          } ${activeText === placeholder ? style.textPlaceholder : ''}`}
          onClick={() => {
            toggleDropDown();
          }}
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.key === ' ' || e.key === 'Enter') toggleDropDown();
          }}
          tabIndex={0}
          aria-haspopup="listbox"
          aria-expanded={isDropDownOpen}
          id={id}
        >
          {activeText}
        </div>

        <div
          className={style.optionsContainer}
          style={{ display: isDropDownOpen ? 'block' : 'none' }}
          tabIndex={-1}
        >
          <div
            className={style.optionsScroller}
            tabIndex={-1}
            role="listbox"
            aria-activedescendant={`${id}-${activeValue}`}
            id={`${id}-listbox`}
          >
            {formattedChildren}
          </div>
        </div>
      </div>
      {touched && error && <div className={style.errorText}>{error}</div>}
    </div>
  );
};

InputSelect.defaultProps = {
  className: '',
  initialValueText: '',
  placeholder: 'dropdown',
};

export default InputSelect;
