// extracted by mini-css-extract-plugin
export var inputField = "InputSelect-module--inputField--1Vw0B";
export var inputSelect = "InputSelect-module--inputSelect--1h4eZ";
export var inputInvalid = "InputSelect-module--inputInvalid--3M7sO";
export var errorText = "InputSelect-module--errorText--3dkvR";
export var inputSelectComponent = "InputSelect-module--inputSelectComponent--3nFCU";
export var textPlaceholder = "InputSelect-module--textPlaceholder--14tuB";
export var inputSelectWrapper = "InputSelect-module--inputSelectWrapper--2C0dg";
export var optionsContainer = "InputSelect-module--optionsContainer--3XMXJ";
export var option = "InputSelect-module--option--1Rv20";
export var optionsScroller = "InputSelect-module--optionsScroller--1q4-_";